import { Link } from "react-router-dom";

const BreadCrumbs = params => {
    let currFilter = params.first ? params.first : "accounts";
    return (
        <div className="breadcrumbs">
            <Link to={"/"}><p>Mym Finder</p></Link>
            <p>&#62;</p>
            <Link to={`/${currFilter}`} onClick={() => {
                params.fClick();
            }}><p>{currFilter}</p></Link>
            {
                params.second && 
                <>
                    <p>&#62;</p>
                    <Link to={`/${params.first}/${params.second}`}><p>{params.second}</p></Link>
                </>
            }
        </div>
    )
}

export default BreadCrumbs;