import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { postAPIWithoutHeader, setUserAccessToken} from '../api/api';
import { useAuth } from '../components/auth';
import {useNavigate} from 'react-router-dom';
import {BiddingPage} from '../components/Bidding';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useState, useEffect } from 'react';

export default function SignIn() {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [openEmail, setOpenEmail] = useState(false);
  const [openVerification, setOpenVerification] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [openNewPassword, setOpenNewPassword] = useState(false);
const [newPassword, setNewPassword] = useState("");

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    // Access the submitted email value here and perform any necessary logic
    setOpenEmail(false);
    setOpenVerification(true);
    console.log("email is ", email);
    const res = await postAPIWithoutHeader("/user/sendemail", {"email": email});
    if  (res.success){
      console.log("email sent");
    }
    
  };
  const handleLostPassword = (event) => {
    event.preventDefault();
    // Add your logic for handling lost passwords here
    setOpenEmail(false);
    setOpenVerification(true);
  };
  const handleVerify = async (event) => {
    // Add your logic for verifying the code here
    console.log("verification code is ", verificationCode);
    const res = await postAPIWithoutHeader("/user/verifypinfp", {"email": email, "pin": parseInt(verificationCode)});
    console.log("res is from verif", res);
    if  (res.success){
    setOpenVerification(false);
    setOpenNewPassword(true);
    }
    else{ 
      console.log("email not verified");
    }
  };
  const handleNewPassword = async (event) => {
    event.preventDefault();
    // Add your logic for setting the new password here
    
    const res = await postAPIWithoutHeader("/user/resetpassword", {"email": email, "password": newPassword});
    
    if  (res.success){
      console.log("password reset");
      setOpenNewPassword(false);
      setOpenSuccess(true);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);



    
    const body={
      email: data.get('email'),
      password: data.get('password'),
    };
    const res = await postAPIWithoutHeader("/user/login", body);
    

    console.log("res is from log in ", res);
    if (res.success){
      setUserAccessToken(res.data.token);
      auth.login();

      navigate('/');
    
    } else {
      console.log("response error", res.data.error)
      setshowerror(true);
      seterrormessage(res.data.error);
    }

  };
  const [showerror , setshowerror] = React.useState(false);// initialize with false
  const [errormessage, seterrormessage] = React.useState("");// initialize with empty string
  const auth = useAuth();
  useEffect(() => {
    if (auth.isLogged) {
      navigate('/');
    }
  }, [auth.isLogged, navigate]);

  return (
    
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'rgb(54, 182, 255)' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
           
           {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            */}
            {showerror && (
            <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                Incorrect email or password
            </Typography>
        )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 ,backgroundColor: 'rgb(54, 182, 255)'}}
            >
              Sign In
            </Button>
            <Grid container>
  <Grid item xs>
    <Link href="#" variant="body2" onClick={(event) => {
      event.preventDefault();
      setOpenEmail(true);
    }}>
      Forgot password?
    </Link>
  </Grid>
 < Dialog open={openEmail} onClose={() => setOpenEmail(false)}
 sx={{ '& .MuiDialog-paper': { borderRadius: '10px' } }}>
  <DialogTitle>Lost Password</DialogTitle>
  <DialogContent>
    <DialogContentText>
      To recover your password, please enter your email address here. We will send you verification code.
    </DialogContentText>
    <TextField
      autoFocus
      margin="dense"
      id="name"
      label="Email Address"
      type="email"
      fullWidth
      value={email}
      onChange={(event) => setEmail(event.target.value)}
      required
  pattern={emailPattern}
  helperText="Please enter a valid email address."
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenEmail(false)}>Cancel</Button>
    <Button onClick={handleEmailSubmit} disabled={!emailPattern.test(email)}>Send</Button>
  </DialogActions>
</Dialog>

<Dialog open={openVerification} onClose={() => setOpenVerification(false)}
sx={{ '& .MuiDialog-paper': { borderRadius: '10px' } }}>
  <DialogTitle>Verify Your Email</DialogTitle>
  <DialogContent>
    <DialogContentText>
      A verification code has been sent to your email address: {/* Display user email */}
    </DialogContentText>
    <TextField
  autoFocus
  margin="dense"
  id="verificationCode"
  label="Verification Code"
  type="text"
  fullWidth
  value={verificationCode}
  onChange={(event) => setVerificationCode(event.target.value)}
  required
  pattern="\d*"
  helperText="Please enter the verification code you received."
/>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenVerification(false)}>Cancel</Button>
    <Button onClick={() => handleVerify(verificationCode)} disabled={!/^\d+$/.test(verificationCode)}>Verify</Button>
  </DialogActions>
</Dialog>

<Dialog open={openSuccess} onClose={() => setOpenSuccess(false)}
sx={{ '& .MuiDialog-paper': { borderRadius: '10px' } }}>
  <DialogTitle>Password Changed Successfully</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Your password has been changed successfully. You can now log in with your new password.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenSuccess(false)}>Close</Button>
  </DialogActions>
</Dialog>
<Dialog open={openNewPassword} onClose={() => setOpenNewPassword(false)}
sx={{ '& .MuiDialog-paper': { borderRadius: '10px' } }}>
  <DialogTitle>Set New Password</DialogTitle>
  <form onSubmit={handleNewPassword}>
    <DialogContent>
      <DialogContentText>
        Please enter your new password.
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="newPassword"
        label="New Password"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
        fullWidth
        pattern={passwordPattern}
        helperText="Must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number."
        autoComplete="new-password"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpenNewPassword(false)}>Cancel</Button>
      <Button type="submit" disabled={!passwordPattern.test(newPassword)}>Submit</Button>
    </DialogActions>
  </form>
</Dialog>
              <Grid item>
                <Link href="#" variant="body2"
                onClick={(event) => {
                  event.preventDefault();
                  navigate('/signup');
                }}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

  );
}