import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom"

const NotFound = () => {
    const navigate = useNavigate();
 

    return(
        <div className="notFound">
            
            <Helmet>
                <title>404 Not Found - MymFinder.com - The Mym Finder - Mym Fans Search</title>
                <meta property="og:title" content="404 Not Found - MymFinder.com - The Mym Finder - Mym Fans Search" />
                <meta property="og:description" content="Find Mym profiles by key words such as name, city, description, hashtags and more."/>
                <meta property="og:image" content="/images/transparentLogo.png" />
            <meta property="og:type" content="website" />
            </Helmet>
            <h1>404</h1>
            <p>Darkness has fallen, and it looks like you're lost :'( <br/>Here's a flashlight to light your way ✊🔦</p>
            <h3 onClick={() => {
                navigate("/",{replace:true})
            }}>Click here to go home</h3>
        </div>
    )
}

export default NotFound