import SocialButton from "./SocialButton"
import { patchAPIWithHeader } from "../api/api"

const SearchResult = params => {
    const handleClick = async name => {
        console.log('name',name);
        console.log('I am inside the handleClick function');
        const res = await patchAPIWithHeader('/user/click', {'mymAccount':name});
        console.log('res',res);
        if(res.success){
            console.log("click registered");
        }

    }
    function cutStringCond(str,len){
        if(str.length < len){
            return str;
        }else{
            return `${str.substring(0,len)}...`
        }
    }

    const redirectToExternalLink = url => {
        window.open(url, '_blank');
    };

    return (
        <div className="result">
            <div className="pfpCont">
                    {/* <img src="/images/mLogoTransparent.png" className="pfp"/> */}
                    <img title={`${params.element.name} Mym profile picture`} title={`${params.element.name} Mym profile picture`} loading="lazy" alt={`${params.element.name} Mym profile picture`} src={params.element.imageS3} className="pfp" onClick={() => {
                        redirectToExternalLink(params.element.link)
                    }}/>
            </div>
            <div>
                <div className="row center breakSmall">
                    <a href={params.element.link} target="_blank" className="nameLink" onClick={() => handleClick(params.element.name)}>
                        <h3>{params.element.name}</h3>
                    </a>
                    <div>
                        <div>
                            <img alt="Location icon" loading="lazy" title="Location icon" src="/images/location.svg"/>
                            <p>{cutStringCond(params.element.city,15).toLowerCase()}</p>
                        </div>
                        <div>
                            <img alt="Hashtag icon" loading="lazy" title="Hashtag icon" src="/images/hashtag.svg"/>
                            <p>{cutStringCond(params.element.hashtags,25).toLowerCase()}</p>

                        </div>

                    </div>
                </div>
                <p>{cutStringCond(params.element.description,250).replace(/\\n/g," ").replace(/\\r/g," ")}</p>
                <div className="row center bottomRow">
                    {params.element.numberOfLikes.trim().length > 0 ?
                    <div>
                        <img alt="Likes icon" loading="lazy" title="Likes icon" src="/images/likes.svg"/>
                        <p>{parseFloat(params.element.numberOfLikes).toLocaleString('en-US')}</p>
                    </div>
                    : null}
                    {params.element.numberOfPosts.trim().length > 0 ? 
                    <div>
                        <img alt="Number of photos icon" title="Number of photos icon" loading="lazy" src="/images/camera.svg"/>
                        <p>{parseFloat(params.element.numberOfPosts).toLocaleString('en-US')}</p>
                    </div>
                     : null}
                    {params.element.price.trim().length > 0 ?
                    <div onClick={() => {
                        redirectToExternalLink(params.element.link)
                    }}>
                        <img alt="Price icon" title="Price icon" loading="lazy" src="/images/price.svg"/>
                        
                        <p>€{parseFloat(params.element.price).toLocaleString('en-US')}</p>
                    </div>
                    : null}
                </div>
                <div className="socialsCont">
                    {
                        params.element.instagram.length > 0 
                            ? <SocialButton network="instagram" link={params.element.instagram}/>
                            : null
                    }
                    {
                        params.element.snapchat.length > 0 
                            ? <SocialButton network="snapchat" link={params.element.snapchat}/>
                            : null
                    }
                    {
                        params.element.tiktok.length > 0 
                            ? <SocialButton network="tiktok" link={params.element.tiktok}/>
                            : null
                    }
                    {
                        params.element.facebook.length > 0 
                            ? <SocialButton network="facebook" link={params.element.facebook}/>
                            : null
                    }
                    {
                        params.element.twitter.length > 0 
                            ? <SocialButton network="twitter" link={params.element.twitter}/>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default SearchResult