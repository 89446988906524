import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik';
import * as yup from 'yup';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import {Box, Typography, TextField, Button} from '@mui/material';
import {getAPIWithHeader, patchAPIWithHeader} from '../api/api';
import {useAuth} from './auth';
import {set} from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const UserInformation = () => {
    const [user, setUser] = useState(null);
    const auth = useAuth();
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await getAPIWithHeader("/user/currentuser");
                console.log("result is in use effect 1 ", res.data);
                console.log("token is", auth.token);
                setUser(res.data);
            } catch (error) {
                console.error("Error fetching user: ", error);
            }
        };

        fetchUser();
    }, []);

    const editFeild = [
        {id: 'firstName', value: 'First Name', type: 'text'},
        {id: 'lastName', value: 'Last Name', type: 'text'},
        {id: 'mymAccount', value: 'Your Mym Account name', type: 'text'},
    ];
    const [isPasswordChange, setIsPasswordChange] = useState(false);


    const validationSchema = yup.object({
        firstName: yup
            .string('Enter your first name')
            .required('First name is required'),
        lastName: yup
            .string('Enter your last name')
            .required('Last name is required'),
        mymAccount: yup.string(),
        password: isPasswordChange
            ? yup
                .string('Enter your password')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    'Password should be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
                )
                .required('Password is required')
            : yup.string(),
        userNeed: yup
            .string('Enter who you are')
            .required('Who you are field is required'),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            mymAccount: '',
            password: '',
            userNeed: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            const res = patchAPIWithHeader("/user/update", values);
            setUser(res.data);
            console.log("inside submit");
            setEdit(false);
            setSubmitting(false);
        },
    });
    useEffect(() => {
        if (user) {
            formik.setValues({
                firstName: user.firstName,
                lastName: user.lastName,
                mymAccount: user.mymAccount,
                userNeed: user.userNeed,
                password: '',
            });
            console.log("user is ", user);
        }
    }, [user]);
    const [edit, setEdit] = React.useState(false);


    return (

        <div>
            <Box key={edit} sx={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                {!edit ? (
                    <>
                        <Typography variant="h6">User Information</Typography>
                        <Typography>First Name: {formik.values.firstName}</Typography>
                        <Typography>Last Name: {formik.values.lastName}</Typography>
                        <Typography>Mym Name: {formik.values.mymAccount
                        }</Typography>
                        <Typography>Password: ***********</Typography>
                        <Typography>Who you are: {formik.values.userNeed}</Typography>
                        <Button variant="contained" onClick={() => {
                            setEdit(true);
                        }} sx={{mt: 2, backgroundColor: 'rgb(54, 182, 255)'}}>
                            Edit
                        </Button>
                    </>
                ) : (
                    <form id="myForm" onSubmit={formik.handleSubmit}>
                        {editFeild.map((item, index) => (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                key={index}
                                label={item.value}
                                name={item.id}
                                type={item.type}
                                value={formik.values[item.id]}
                                onChange={formik.handleChange}
                                error={formik.touched[item.id] && Boolean(formik.errors[item.id])}
                                helperText={formik.touched[item.id] && formik.errors[item.id]}
                            />
                        ))}
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Change Who You Are</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="userNeed"
                                name="userNeed"

                                value={formik.values.userNeed}
                                label="Choose your need"
                                onChange={formik.handleChange}
                                error={formik.touched.userNeed && Boolean(formik.errors.userNeed)}
                                helperText={formik.touched.userNeed && formik.errors.userNeed}
                            >
                                <MenuItem value={"User"}>User</MenuItem>
                                <MenuItem value={"Creator"}>Creator</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isPasswordChange}
                                    onChange={(event) => setIsPasswordChange(event.target.checked)}
                                    name="isPasswordChange"
                                />
                            }
                            label="Change Password"
                        />

                        {isPasswordChange && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Password"
                                name="password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        )}
                        <Button variant="contained" fullWidth type="submit"
                                sx={{mt: 2, backgroundColor: 'rgb(54, 182, 255)'}}>
                            Save
                        </Button>
                    </form>
                )}
            </Box>
        </div>
    )
}

export default UserInformation
