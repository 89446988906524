import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import {useTheme, createTheme, ThemeProvider} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {Label} from 'recharts';
import {LineChart, Line} from 'recharts';
import {Box, InputAdornment} from '@mui/material';
import {getAPIWithHeader, patchAPIWithHeader, postAPIWithHeader} from '../api/api';
import CurrencyInput from "react-currency-input-field";

function BiddingPage() {

    const [isBiddingActive, setIsBiddingActive] = useState(false);
    const [costPerClick, setCostPerClick] = useState('');
    const [totalClicks, setTotalClicks] = useState(0);
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [clickData, setClickData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(true);
    const [paymentNoticeVisible, setPaymentNoticeVisible] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [graphData, setGraphData] = useState([]); // [ { day: 1, clicks: 0 }, ...
    const primaryColorRGB = '54, 182, 255';
    const [isBidSubmitted, setIsBidSubmitted] = useState(false);

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Day ${label} : ${payload[0].value} clicks`}</p>
                </div>
            );
        }

        return null;
    };
    const handleCPCChange = (inputValue) => {
        setInputError(isInvalidFloat(inputValue));
        setCostPerClick(inputValue);
    };

    const isInvalidFloat = (value) => {
        return value === null || value < 0.5;
    };
    useEffect(() => {
        setIsButtonDisabled(inputError || costPerClick === '');
    }, [costPerClick, inputError]); // Track dependencies
    const theme = useTheme();
    const customTheme = createTheme({
        palette: {
            primary: {
                main: 'rgb(54, 182, 255)',
            },
        },
        shadows: Array(25).fill('none'),
    });

    useEffect(() => {
        const getinitialData = async () => {
            // Placeholder for fetching initial data

            console.log("inside useeffect");
            const res = await getAPIWithHeader('/user/currentuser');
            console.log("about to call graph data");
            const Gres = await getAPIWithHeader('/user/graphdata');
            console.log("Gres000000000", Gres.data);

            const data = [];
            for (let i = 0; i < Gres.data.lastforteenDays.length; i++) {
                data.push({day: i + 1, clicks: Gres.data.lastforteenDays[i]});
            }
            setGraphData(data);

            console.log(res.data);

            setGraphData(data);

            if (res.data.payment_method_added) {
                setHasPaymentMethod(true);
                console.log('Payment method found');
            } else {
                console.log('No payment method found');
                setHasPaymentMethod(false);
            }
            if (res.data.require_bid_service) {
                setIsBiddingActive(true);
                setCostPerClick(res.data.bid_price);
                const totalClicksCount = Gres.data.lastforteenDays.reduce((acc, cur) => acc + cur, 0);
                setTotalClicks(totalClicksCount);
            }
        }

        getinitialData();
    }, []);

    useEffect(() => {
        // When isBiddingActive is true, fetch real-time click data
        // and update totalClicks and clickData with the received information
        setEstimatedCost(totalClicks * costPerClick);

    }, [isBiddingActive, costPerClick, totalClicks]);

    const handleBiddingToggle = () => {
        if (!hasPaymentMethod) {
            setPaymentNoticeVisible(true);
            return;
        }

        if (isBiddingActive) {
            setOpenDialog(true);
        } else {
            setIsBiddingActive(true);
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogConfirm = async () => {

        const res = await patchAPIWithHeader("/user/turnoffbid", {"require_bid_service": false});
        console.log(res)
        if (res.success) {
            setIsBiddingActive(false);
            setOpenDialog(false);
        }
    };


    const handleSubmit = async () => {
        console.log('Bidding submitted:', isBiddingActive, costPerClick);
        setIsBidSubmitted(true);
        const res = await patchAPIWithHeader("/user/update", {
            "require_bid_service": isBiddingActive,
            "bid_price": costPerClick
        });
        console.log('res', res);
    };


    return (
        <ThemeProvider theme={customTheme}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom>
                        Bidding Setup
                    </Typography>

                    <Switch checked={isBiddingActive} onChange={handleBiddingToggle}/>
                    <Typography>Activate Bidding</Typography>
                    {paymentNoticeVisible && (
                        <Typography color="error" sx={{marginTop: 1}}>
                            Please add a payment method to enable bidding.
                        </Typography>
                    )}
                    <Grid item xs={12}> {/* Wrap content to hide */}
                        {isBiddingActive && (
                            <>
                                <TextField
                                    label="Cost Per Click (CPC)"
                                    margin="normal" // Adds spacing around the TextField
                                    required // Adds a red asterisk to indicate required field
                                    fullWidth // Make the field take up full width
                                    error={inputError} // Shows red border if there's an error
                                    helperText={inputError && 'Please enter a valid positive number and it should be minimum €0,5.'} // Shows error message below input
                                    InputLabelProps={{
                                        shrink: true, // Prevent label overlap when input has content
                                    }}
                                    InputProps={{
                                        inputComponent: CurrencyInput,
                                        inputProps: {
                                            defaultValue: costPerClick,
                                            onValueChange: (_value, _name, values) => handleCPCChange(values.float),
                                            intlConfig: {locale: 'fr-FR', currency: 'EUR'},
                                        },
                                    }}
                                />
                                <Typography> Minimum of 0,5€ per
                                    profile click </Typography>
                                <Button variant="contained" disabled={isButtonDisabled} onClick={handleSubmit}>
                                    Submit Bid
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    {isBiddingActive && (
                        <Card sx={{
                            padding: '30px',
                            boxShadow: `0 0 15px 10px rgba(${primaryColorRGB}, 0.3)`, // create a blurred outline
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 300, // adjust this to your liking
                            borderRadius: '15px' // round the corners
                        }}>
                            <Box display="flex" justifyContent="flex-end">
                                <Typography variant="h4" sx={{color: '#36b6ff', fontWeight: 'bold'}}>
                                    Total Clicks in this Cycle: {totalClicks}
                                </Typography>
                            </Box>
                            <ResponsiveContainer width="95%" height="80%">
                                <LineChart data={graphData}>
                                    <Line type="monotone" dataKey="clicks" stroke="#36b6ff" strokeWidth={2}/>
                                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3"/>
                                    <XAxis dataKey="day" stroke="#36b6ff" height={60}>
                                        <Label value="Days" position="insideBottom"/>
                                    </XAxis>
                                    <YAxis stroke="#36b6ff" width={60}>
                                        <Label value="Clicks" angle={-90} position="insideLeft"/>
                                    </YAxis>
                                    <Tooltip/>
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    )}
                </Grid>

                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogTitle>Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to turn off bidding?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={handleDialogConfirm} autoFocus>
                            Turn Off
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isBidSubmitted}
                    onClose={() => setIsBidSubmitted(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: {borderRadius: 25}, // Inline style for rounded corners
                    }}
                >
                    <DialogTitle id="alert-dialog-title">{"Bid Submitted"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Your bid has been submitted and is now active.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsBidSubmitted(false)} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </ThemeProvider>
    );
}

export default BiddingPage;
