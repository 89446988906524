import axios from "axios";
import { useNavigate } from "react-router-dom"
import { BASE_SERVER_URL } from "../data/urls";
import { useState } from "react";

const UploadCSV = () => {
    const navigate = useNavigate();
    const [uploadStatus,setUploadStatus] = useState("");
    const [isUploading,setIsUploading] = useState(false) 
    const logout = async () => {
        try{
            await axios.request({
                method:'post',
                url:`${BASE_SERVER_URL}/logout`,
                withCredentials:true
            });
            navigate("/upload",{replace:true})
        }catch(e){
            console.log(e);
        }
    }
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    
    const handleFileUpload = async () => {
        setIsUploading(true)
        const formData = new FormData();
        formData.append('file', file);
        setUploadStatus("uploading...")

        try {
            await axios.post(`${BASE_SERVER_URL}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials:true
            });
            setUploadStatus("Uploaded!")
        } catch (error) {
            setUploadStatus('Error uploading file, try to refresh');
        }
        setIsUploading(false);
    }; 
    return (<div>
        <div>
            <input type="file" onChange={handleFileChange} accept=".csv"/>
            <button onClick={handleFileUpload} style={{display:isUploading ? "none" : "block"}}>Upload</button>
        </div>
        <h1>{uploadStatus}</h1>
        <button onClick={logout}>Log out</button>
    </div>)
}

export default UploadCSV