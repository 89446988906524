import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import UserInformation from '../components/UserInformation';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useAuth} from '../components/auth';
import BiddingPage from '../components/Bidding';
import PaymentForm from '../components/PaymentForm';
import {Link} from "react-router-dom";

export default function ClippedDrawer() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [activePage, setActivePage] = React.useState('User Information');
    const handlePageClick = (page) => {
        setActivePage(page);
    };
    const drawerWidth = isMobile ? 60 : 240;

    const auth = useAuth();
    const renderPageContent = () => {
        switch (activePage) {
            case 'User Information':
                return (<UserInformation/>);
            case 'Payment Method':
                return <PaymentForm/>;
            case 'Bidding':
                return <BiddingPage/>;
            default:
                return null;
        }
    };

    return (<Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <AppBar position='fixed'
                sx={{zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "rgb(255, 255,255)"}}>
            <Toolbar>
                <Link to={'/'}>
                    <img src="/images/MymF.png" height="100px" width="100px" alt="Mym Finder"/>
                </Link>
            </Toolbar>
        </AppBar>

        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
            }}
        >
            <Toolbar/>
            <Box sx={{overflow: 'auto', pt: 5}}>
                <List>
                    {['User Information', 'Payment Method', 'Bidding'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton onClick={() => handlePageClick(text)}>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                                </ListItemIcon>
                                {!isMobile && <ListItemText primary={text}/>}
                            </ListItemButton>
                        </ListItem>))}
                </List>
            </Box>
        </Drawer>
        <Box component="main" sx={{flexGrow: 1, p: 3, pt: 7}}>
            <Toolbar/>
            {renderPageContent()}
        </Box>
    </Box>);
}
