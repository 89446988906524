import React from 'react';
import { useNavigate } from 'react-router-dom';

const DivertPage = () => {
  const navigate = useNavigate();

  return (
    <div className="divertPage">
      <button type="button" onClick={() => navigate('/adminpage')}>Go to Admin Page</button>
      <button type="button" onClick={() => navigate('/upload-file')}>Go to Upload File Page</button>
    </div>
  );
}

export default DivertPage;