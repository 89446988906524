import React, {useState, useEffect} from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import {Card, CardContent, Container, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {countries} from 'country-data';
import MenuItem from '@mui/material/MenuItem';
import {getAPIWithHeader, patchAPIWithHeader} from '../api/api';

const Form = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [paymentExists, setPaymentExists] = useState(false);
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [sessionError, setSessionError] = useState('');
    const fields = [
        {label: "First Name", value: firstName, setter: setFirstName, grid: {xs: 12, sm: 6}, required: true},
        {label: "Last Name", value: lastName, setter: setLastName, grid: {xs: 12, sm: 6}, required: true},
        {label: "Street Address", value: line1, setter: setLine1, grid: {xs: 12}, required: true},
        {label: "Apartment, suite, etc.", value: line2, setter: setLine2, grid: {xs: 12}, required: false},
        {label: "City", value: city, setter: setCity, grid: {xs: 12, sm: 6}, required: true},
        {label: "State", value: state, setter: setState, grid: {xs: 12, sm: 6}, required: false},
        {label: "Postal Code", value: postalCode, setter: setPostalCode, grid: {xs: 12, sm: 6}, required: true},
    ];
    useEffect(() => {
        async function fetchPaymentMethod() {
            // Set paymentExists to true IF YOOU ALREADY HAVE PAYMENT METHOD DATA
            // Placeholder for fetching payment method data
            const res = await getAPIWithHeader('/user/currentuser');
            setPaymentExists(res.data.payment_method_added);
            if (res.data.payment_method_added) {
                setFirstName(res.data.invoice.firstName);
                setLastName(res.data.invoice.lastName);
                setCity(res.data.invoice.city);
                setState(res.data.invoice.state);
                setPostalCode(res.data.invoice.postal_code);
                setCountry(res.data.invoice.country);
                setLine1(res.data.invoice.line1);
                setLine2(res.data.invoice.line2);
            }
        }

        fetchPaymentMethod();
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("in handle submit");

        const body = {
            invoice: {
                firstName: `${firstName}`,
                lastName: `${lastName}`,
                city: city,
                state: state || '_',
                postal_code: postalCode,
                country: country,
                line1: line1,
                line2: line2 || '_',
            }
        };

        const res = await patchAPIWithHeader('/user/update', body);

        if (res.success) {
            setPaymentExists(true);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                {fields.map(({label, value, setter, grid, required}, index) => (
                    <Grid item key={index} {...grid}>
                        <TextField
                            label={label}
                            value={value}
                            onChange={(e) => setter(e.target.value)}
                            fullWidth
                            disabled={paymentExists}
                            required={required}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} sm={6}>
                    <TextField
                        select
                        label="Country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        fullWidth
                        required={true}
                        disabled={paymentExists}
                    >
                        {countries.all
                            .filter(({status}) => status === 'assigned')
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(({alpha2, name}) => (
                                <MenuItem key={alpha2} value={alpha2}>
                                    {name}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type='button'
                        onClick={async () => {
                            const {origin} = window.location;
                            const link = origin + '/profile';
                            const res = await getAPIWithHeader(`/user/getCheckoutSession?successUrl=${link}`);

                            if (res.success) {
                                window.location.href = res.data.session;
                            } else {
                                setSessionError(res.data.error)
                            }
                        }}
                        style={{backgroundColor: '#36b6ff', color: 'white'}}
                    >
                        Update Payment Info
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        {paymentExists ? (
                            <Button key="update-button" onClick={() => setPaymentExists(false)}
                                    style={{backgroundColor: '#36b6ff', color: 'white'}}>
                                Update Info
                            </Button>
                        ) : (
                            <Button type="submit" style={{backgroundColor: '#36b6ff', color: 'white'}}>
                                Save Info
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {sessionError && (
                    <Grid item xs={12}>
                        <p style={{color: 'red'}}>{sessionError}</p>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

const PaymentForm = () => {
    return (
        <Container>
            <Typography variant="h4" style={{marginTop: '20px', marginBottom: '20px', color: '#36b6ff'}}>
                Payment Information
            </Typography>
            <Card>
                <CardContent>
                    <Form/>
                </CardContent>
            </Card>
        </Container>
    );
};

export default PaymentForm;
