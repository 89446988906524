const SearchBar = params => {
    return (
        <form className="searchBar" onSubmit={params.searchFunction}>
            <input type="text" value={params.query} placeholder="Search by Name, Hashtags, City, Description and more ..." onChange={ e=> {
                params.setQuery(e.target.value);
            }}/>
            <button type="submit">
                <img alt="Search Icon" title="Search Icon" loading="lazy" src="/images/search.svg"/>
            </button>
        </form>
    )
}

export default SearchBar;