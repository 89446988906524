import { Navigate, Outlet, useLocation, useParams } from "react-router-dom"
import NotFound from "../pages/NotFound";

const FilterFilter = () => {
    const searchParams = useParams();
    const location = useLocation();

    if(searchParams.filter.toLowerCase() == "new" || searchParams.filter.toLowerCase() == "free" || searchParams.filter.toLowerCase() == "top" || searchParams.filter.toLowerCase() == "accounts"){
        return <Outlet/>
    }else{
        // setFilter("accounts")
        return <Navigate to="/404" state={{from:location}}/>
    }
}

export default FilterFilter