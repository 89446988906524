import ButtonPrompt from "../components/ButtonPrompt";
import FilterBtn from "../components/FilterBtn";
import SearchBar from "../components/SearchBar";
import TransLogo from "../components/TransLogo";
import {useState} from "react";
import {useNavigate} from 'react-router-dom'
import SendProfile from "../components/SendProfile";
import axios from "axios";
import {BASE_SERVER_URL} from "../data/urls";
import CustomDropdown from "../components/CustomDropdown";
import {Helmet} from 'react-helmet'
import {useAuth} from "../components/auth";
import AccountMenu from "../components/AccountMenu";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";

const MainSearch = () => {
    const auth = useAuth();
    const [searchText, setSearchText] = useState("");
    const [sendProfileOpen, setSendProfileOpen] = useState(false);
    const [startMymOpen, setStartMymOpen] = useState(false);
    const navigate = useNavigate();

    const searchFunction = e => {
        e.preventDefault();
        navigate(`/accounts/${searchText}`, {replace: true})
    }

    const gaEventTracker = useAnalyticsEventTracker('Push my mym account');

    const openSendProfile = () => {
        setSendProfileOpen(true);
        gaEventTracker('Open Send Profile modal');
    }

    const closeSendProfile = e => {
        if (e && e.target.className != "blur" && e.target.className != "cl") {
            return;
        }
        setSendProfileOpen(false);
    }
    const openStartMym = () => {
        setStartMymOpen(true);
    }
    const closeStartMym = e => {
        if (e && e.target.className != "blur" && e.target.className != "cl") {
            return;
        }
        setStartMymOpen(false);
    }
    const sendProfile = async (name, email, formType) => {
        try {
            await axios.request({
                url: `${BASE_SERVER_URL}/sendMail`,
                method: 'post',
                data: {
                    name: name,
                    email: email,
                    formType: formType
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            return true;
        } catch {
            return false;

        }
        await sleep(1000)
    }
    const sleep = async ms => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve()
            }, ms)
        })
    }

    const handleSelect = (el) => {
        // navigate(`/results?query=${el}`,{replace:true})
        navigate(`/accounts/${el.toLowerCase()}`, {replace: true})
    }

    const navSearch = (active, filter) => {
        // navigate(`/results?query=${searchText}&filter=${filter}`,{replace:true})
        console.log(filter, searchText)
        navigate(`/${filter.toLowerCase()}/${searchText}`, {replace: true})
    }
    const openLogIn = () => {
        navigate("/login")

    }
    const openSignUp = () => {
        navigate("/signup")


    }
    return <>
        <div className="page-body">
            <div className="nav-bar-container">
                <div className="nav-bar">
                    <div className="login-buttons">
                        {!auth.isLogged ? (
                            <>
                                <ButtonPrompt btnText="Log In" click={openLogIn}/>
                                <ButtonPrompt btnText="Sign Up" click={openSignUp}/>
                            </>
                        ) : (
                            <>
                                <AccountMenu/>
                            </>
                        )}
                    </div>
                </div>
            </div>


            <div className="searchCont">
                <Helmet>
                    <title>The Mym search engine</title>
                    <link rel="canonical" href={`${window.location.href}`}/>
                    <meta property="og:type" content="website"/>
                    <meta name="description"
                          content="Find Mym profiles by key words such as name, city, description, hashtags and more."></meta>
                    <meta property="og:title" content="MymFinder.com - The Mym Finder - Mym Fans Search"/>
                    <meta property="og:url" content={`${window.location.href}`}/>
                    <meta property="og:description"
                          content="Find Mym profiles by key words such as name, city, description, hashtags and more."/>
                    <meta property="og:image" content="/images/transparentLogo.png"/>
                </Helmet>
                <SendProfile show={sendProfileOpen} closeFunction={closeSendProfile} handleForm={sendProfile}
                             formType="sendProfile" nameForm="Mym Username"/>
                <SendProfile show={startMymOpen} closeFunction={closeStartMym} handleForm={sendProfile}
                             formType="getPushed" nameForm="Name"/>

                <div className="innerSearchCont">
                    <TransLogo/>
                    <h1>The Mym search engine - Find Mym profiles by key words</h1>
                    <SearchBar query={searchText} setQuery={setSearchText} searchFunction={searchFunction}/>
                    <div className="filterBtns">
                        <FilterBtn filter="Top" query={searchText} clickFunction={navSearch}/>
                        {/* <FilterBtn filter="Free" query={searchText}/> */}
                        <FilterBtn filter="New" query={searchText} clickFunction={navSearch}/>
                        <CustomDropdown handleSelect={handleSelect}/>
                    </div>
                    <div className="prompts">
                        <ButtonPrompt text="Push my mym account" btnText="Send Profile" click={openSendProfile}/>
                    </div>
                </div>
            </div>
        </div>
        ;
    </>
}

export default MainSearch;
