import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import {useFormik} from 'formik';
import {useAuth} from '../components/auth';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import * as yup from 'yup';
import {
    postAPIWithoutHeader,
    setUserAccessToken,
    getUserAccessToken,
    postAPIWithHeader
} from '../api/api';
import _, {get, set} from 'lodash';
import {TagsInput} from "react-tag-input-component";


export default function SignUp() {
    const [verificationCode, setVerificationCode] = useState("");
    const [selectedHashtags, setSelectedHashtags] = useState([]);
    const auth = useAuth();
    const validationSchema = yup.object({
        firstName: yup
            .string('Enter your first name')
            .required('First name is required'),
        lastName: yup
            .string('Enter your last name')
            .required('Last name is required'),
        mymAccount: yup.string(),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string('Enter your password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                'Password should be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
            )
            .required('Password is required'),
        confirmPassword: yup
            .string('Enter your password')
            .required('Password is required')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
        userNeed: yup
            .string('Who you are')
            .required('Who you are field is required'),
    });
    let errorMessage = "";


    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            mymAccount: '',
            email: '',
            password: '',
            userNeed: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (formik.isValid) {
                console.log("inside onsubmit");
                const request = _.omit(values, 'confirmPassword');
                if (request.userNeed === "Creator" && request.mymAccount.length < 1) {
                    formik.setFieldError("mymAccount", "Please enter a Mym account name");
                    return;
                }

                request.mymAccount = values.mymAccount.slice(0, 1).toUpperCase() + values.mymAccount.slice(1).toLowerCase(); // capitalize first letter of mymAccount
                request.hashtags = request.userNeed === "Creator" ? [] : selectedHashtags;
                const apicall = await postAPIWithoutHeader('/user/signup', request);
                console.log(apicall);
                if (apicall.success) {
                    setOpen(true);
                    setshowerror(false);
                    setUserAccessToken(apicall.data.token);


                } else {
                    seterrormessage(apicall.data.error);
                    setshowerror(true);
                }
            }
        },
    });


    const navigate = useNavigate();
    const handleClose = () => {
        setOpen(false);
    };
    const handleVerify = async (verificationCode) => {
        console.log('THIS IS VERIFCATION CODE ', verificationCode);
        const res = await postAPIWithHeader('/user/verify', {"pin": parseInt(verificationCode)});
        console.log(res)
        if (res.success) {
            auth.login();
            navigate('/');
            setOpen(false);
        } else {
            // Display error message
            // implement the incorrect pin logic
            console.log("response failed");
        }

        console.log("inside function", verificationCode);
    };
    const [open, setOpen] = React.useState(false);
    const [showerror, setshowerror] = React.useState(false);// initialize with false
    const [errormessage, seterrormessage] = React.useState("");// initialize with empty string
    useEffect(() => {
        if (auth.isLogged) {
            navigate('/');
        }
    }, [auth.isLogged, navigate]);

    return (

        <Container component="main" maxWidth="xs">

            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'rgb(54, 182, 255)'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                value={formik.values.firstName}
                                autoFocus
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Who you are*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="userNeed"
                                    name="userNeed"

                                    value={formik.values.userNeed}
                                    label="Who you are"
                                    onChange={formik.handleChange}
                                    error={formik.touched.userNeed && Boolean(formik.errors.userNeed)}
                                    helperText={formik.touched.userNeed && formik.errors.userNeed}
                                >
                                    <MenuItem value={"User"}>User</MenuItem>
                                    <MenuItem value={"Creator"}>Creator</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="mymAccount"
                                label="Your Mym Account name"
                                name="mymAccount"
                                autoComplete="family-name"
                                value={formik.values.mymAccount}
                                onChange={formik.handleChange}
                                error={formik.touched.mymAccount && Boolean(formik.errors.mymAccount)}
                                helperText={formik.touched.mymAccount && formik.errors.mymAccount}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                        </Grid>
                        {
                            formik.values.userNeed === "User" &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TagsInput
                                        value={selectedHashtags}
                                        onChange={setSelectedHashtags}
                                        name="hashtags"
                                        placeHolder="Favorite hashtags"
                                    />
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2, backgroundColor: 'rgb(54, 182, 255)'}}


                    >
                        Sign Up
                    </Button>
                    <Dialog open={open}
                            sx={{'& .MuiDialog-paper': {borderRadius: '25px'}}}>
                        <DialogTitle>Verify Your Email</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                A verification code has been sent to your email address: {/* Display user email */}
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Verification Code"
                                type="text"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                required
                                pattern="\d*"
                                helperText="Please enter the verification code you received."
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={() => handleVerify(verificationCode)}
                                    disabled={!/^\d+$/.test(verificationCode)}>Verify</Button>

                        </DialogActions>
                    </Dialog>
                    {showerror && (
                        <Typography variant="body2" color="error" align="center" sx={{mt: 2}}>
                            {errormessage}
                        </Typography>
                    )}

                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2"
                                  onClick={(event) => {
                                      event.preventDefault();
                                      navigate('/login');
                                  }}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </Container>
    );
}
